import { FileDto } from './file.dto'
import * as _ from 'lodash'

export class ProfileDto {
  firstName: string
  lastName: string
  email?: string
  photo: FileDto
  roleNames: string[]
  id: string

  static createForm({ firstName, lastName, email, photo }: ProfileDto) {
    return _.assign<ProfileDto>({
      firstName,
      lastName,
      email,
      photo,
    })
  }

  static create() {
    return Object.assign(new ProfileDto())
  }

}

import { assign } from 'lodash'

export class UpdateProfileDto {
  firstName: string
  lastName: string

  static create({ firstName, lastName }: ProfileDto) {
    return assign<UpdateProfileDto>({
      firstName,
      lastName
    })
  }
}
