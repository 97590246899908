import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'

import { HttpService, AuthService, LocalStorageService, FileService, UserService, ProfileService, PromotionService, SettingService, PageService, AllowPhoneNumberService, VoucherService, MovieService, ScheduledFilmService, CinemaProviderService, CinemaService, EventCategoryService, EventService, EventTicketService, MovieAttributeService, WelcomeBannerService, OrderService, ConcessionService, AreaCategoryService } from '@api/services'
import { ApiModuleOptions } from './api.module-options'
import { WindowRef } from '../services/window-ref.service'
import { BannerService } from '../services/admin/banner.service'
import { DragulaService } from 'ng2-dragula'


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    DragulaService,
    AuthService,
    UserService,
    ProfileService,
    PromotionService,
    MovieService,
    CinemaProviderService,
    ScheduledFilmService,
    SettingService,
    PageService,
    VoucherService,
    WindowRef,
    AllowPhoneNumberService,
    CinemaService,
    BannerService,
    EventService,
    EventCategoryService,
    EventTicketService,
    MovieAttributeService,
    WelcomeBannerService,
    OrderService,
    ConcessionService,
    AreaCategoryService,
    AuthStore,
    AuthQuery
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders<ApiModule> {
    // window['serverURL'] = options.serverURL

    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
