import { Injectable } from '@angular/core'

import { PageDetailDto, PageDto, PageResultDto, PagingDto, UpdatePageDto } from '../../dto'
import { HttpService } from '../http.service'

@Injectable()
export class PageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<PageDto>>('/admin/v1/page', pagingDto)
  }

  async findOne(pageId: string) {
    return this.httpService.get<PageDetailDto>(`/admin/v1/page/${pageId}`)
  }

  async update(pageId: string, updatePageDto: UpdatePageDto) {
    return await this.httpService.put<boolean>(`/admin/v1/page/${pageId}`, updatePageDto)
  }
}
