import { BaseDto } from "./base.dto"
import { CinemaDto } from "./cinema.dto"
import { FilmSessionDto, MovieDto } from "./movie.dto"

import { PagingDto } from "./paging.dto"
import { PaymentDto } from "./payment.dto"
import { UserDto } from "./user.dto"

export enum OrderStatus {
  NEW = 'new',
  CLAIMED = 'claimed',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  Expired = 'expired'
}

export enum PaymentMethod {
  PAYWAY = 'aba_payway',
  ABA_CARD = 'aba_card',
  KHQR = 'khqr'
}

export enum PaymentStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export class FindOrderDto extends PagingDto {
  searchText?: string
  channelId?: string
  cinemaProviderId?: string
  startDate?: Date
  endDate?: Date
}

export class ExportOrderDto {
  startDate?: Date
  endDate?: Date
  status?: string
  ownerId?: string
}

export class OrderItemDto {
  id: string
  name: string
  ticketId: string
  ticketTypeCode: string
  seats: any[]
  seatDisplay: string
  type: string
  discountTotal: number
  subTotal: number
  total: number
}

export class OrderDto extends BaseDto {
  orderNumber: string
  owner: UserDto
  payment: PaymentDto
  movie: MovieDto
  cinema: CinemaDto
  channel: string
  type: string
  cinemaProviderBookingId: string
  customerEmail: string
  discountPercentage: number
  discountTotal: number
  subTotal: number
  total: number
  status: string
  items: OrderItemDto[]
  session: FilmSessionDto
  sessionStartTime: Date
  completeErrorData: any
  completeData: any
  discountData: any
  cinemaProvider: string

  static create() {
    return new OrderDto()
  }
}

export class FindOrderDateFilterDto {
  startDate?: Date
  endDate?: Date
  channelId?: string
  cinemaProviderId?: string
}

export class ExportOrderResultDto {
  result: string
}