import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { UpdateProfileDto, ProfileDto } from './../dto'

import { HttpService } from './http.service'
import { FileService } from './file.service'
import { AuthStore } from '@api/store/auth'

@Injectable()
export class ProfileService {
  constructor(
    private authStore: AuthStore,
    private httpService: HttpService,
    private fileService: FileService) {

  }

  async current() {
    const user = await this.httpService.get<ProfileDto>('/v1/profile')
    const authUser = _.clone(user)

    this.authStore.update({ user: authUser })

    return user
  }

  async update({ firstName, lastName }: UpdateProfileDto) {
    return await this.httpService.put<boolean>('/v1/profile', { firstName, lastName })
  }

  async uploadPhoto(file: File) {
    const { id: fileId } = await this.fileService.upload(file, true)

    return await this.httpService.post<boolean>('/v1/profile/photo', { fileId })
  }
}
