import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { HttpService } from '../http.service'

import { AreaCategoryDto, FindAreaCategoryDto, PageResultDto } from '../../dto'

@Injectable()
export class AreaCategoryService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findAreaCategoryDto: FindAreaCategoryDto) {
    return await this.httpService.get<PageResultDto<AreaCategoryDto>>(`/admin/v1/area-categories`, findAreaCategoryDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<AreaCategoryDto>(`/admin/v1/area-categories/${id}`)
  }

  async setIcon(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/area-categories/${id}/icon`, { fileId: iconId })
  }

  async setSelectedIcon(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/area-categories/${id}/selected-icon`, { fileId: iconId })
  }

  async setUnavailableIcon(id: string, iconId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/area-categories/${id}/unavailable-icon`, { fileId: iconId })
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/area-categories/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/area-categories/${id}/disable`)
  }

  async setActive(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/area-categories/${id}/active`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/area-categories/${id}/inactive`)
  }
}
