import * as moment from 'moment'
import _ from 'lodash'

import { BaseDto } from './base.dto'
import { FileDto } from './file.dto'
export class UserDto extends BaseDto {
  firstName: string
  lastName: string
  email: string
  enabled: boolean
  roleNames: string[] = []
  photo: FileDto
  photoId: string

  static create(user?: UserDto) {
    return Object.assign(new UserDto(), user)
  }
}

export class UserDetailDto extends BaseDto {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  dateOfBirth: Date
  roleNames: string[] = []
  photo: FileDto

  static create(user?: UserDetailDto) {
    return _.assign(new UserDetailDto(), {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      dateOfBirth: user?.dateOfBirth ? moment(user.dateOfBirth).toDate() : null,
      photo: user?.photo
    })
  }
}

export class CreateUserDto {
  firstName: string
  lastName: string
  email: string
  password: string
  roleName: RoleNames
  photoId?: string

  static create(data: CreateUserDto) {
    return Object.assign(new CreateUserDto(), data)
  }
}

export class UpdateUserDto {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  dateOfBirth?: string
  roleName?: RoleNames
  photoId?: string

  static create(data: UpdateUserDto) {
    return Object.assign(new UpdateUserDto(), data)
  }
}

export enum RoleNames {
  SUPER_ADMIN = 'SUPER_ADMIN',
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
  EVENT_CHECKER = 'EVENT_CHECKER'
}