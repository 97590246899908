import _ from 'lodash'

import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"
import { TranslationDto } from './translation.dto'

export class PromotionDto extends BaseDto {
  title: TranslationDto
  thumbnail: FileDto
  startedDate: string
  expiredDate: string
  published: boolean
  enabled: boolean
}

export class PromotionDetailDto extends BaseDto {
  title?: TranslationDto
  content?: TranslationDto
  buttonLabel?: TranslationDto
  link?: string
  thumbnail?: FileDto
  thumbnailId?: string
  type?: string
  image?: FileDto
  imageId?: string
  startedDate?: string
  expiredDate?: string
  publishedNotification?: boolean
  enabled?: boolean
  published?: boolean

  static create({ id, title, content, buttonLabel, link, enabled, thumbnail, startedDate, expiredDate, publishedNotification, published, image }: PromotionDetailDto) {
    return _.assign<PromotionDetailDto>({
      id: id || null,
      title: title || TranslationDto.create(),
      content: content || TranslationDto.create(),
      buttonLabel: buttonLabel || TranslationDto.create(),
      link: link || null,
      enabled: enabled,
      published: published,
      startedDate: startedDate ? new Date(startedDate) : null,
      expiredDate: expiredDate ? new Date(expiredDate) : null,
      thumbnail: thumbnail || null,
      thumbnailId: thumbnail?.id || null,
      image: image || null,
      imageId: image?.id || null,
      publishedNotification: publishedNotification
    })
  }
}

export class CreatePromotionDto {
  title: TranslationDto
  content: TranslationDto
  buttonLabel: TranslationDto
  link: string
  thumbnailId: string
  imageId: string
  startedDate: string
  expiredDate: string
  type: PromotionType
}

export class UpdatePromotionDto {
  title?: TranslationDto
  content?: TranslationDto
  buttonLabel?: TranslationDto
  link?: string
  thumbnailId?: string
  imageId?: string
  enabled?: boolean
  startedDate?: string
  expiredDate?: string
  type?: PromotionType
}

export enum PromotionType {
  EVENTS = 'events',
  MOVIES = 'movies'
}

export class FindPromotionDto extends PagingDto {
  searchText?: string
  type: PromotionType
}