import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"
import { TranslationDto } from "./translation.dto"
import _ from 'lodash'
export class FindConcessionDto extends PagingDto {
  searchText?: string
}

export class ConcessionDto extends BaseDto {
  cinemaProviderConcessionId: string
  description: TranslationDto
  title: TranslationDto
  shortName: string
  mobileBannerImage: FileDto
  bannerImage: FileDto
  price: number
  cinemaProviderCinemaId: string
  cinemaProvider: string

  static create(concession?: ConcessionDto) {
    return _.assign<ConcessionDto>(new ConcessionDto, {
      cinemaProviderConcessionId: concession?.cinemaProviderConcessionId,
      description: concession?.description ?? TranslationDto.create(),
      title: concession?.title ?? TranslationDto.create(),
      shortName: concession?.shortName,
      mobileBannerImage: concession?.mobileBannerImage ?? null,
      bannerImage: concession?.bannerImage ?? null,
      price: concession?.price,
      cinemaProviderCinemaId: concession?.cinemaProviderCinemaId,
      cinemaProvider: concession?.cinemaProvider
    })
  }
}

export class UpdateConcessionDto {
  description?: TranslationDto
  title?: TranslationDto
}
