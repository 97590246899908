import { BaseDto } from "./base.dto"

import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"

export class AreaCategoryDto extends BaseDto {
  description: string
  shortDescription: string
  areaCode: string
  seatAllocation: boolean
  icon: FileDto
  selectedIcon: FileDto
  unavailableIcon: FileDto
  enabled: boolean
  cinemaProviderCinemaId: string
  active: boolean

  static create() {
    return new AreaCategoryDto()
  }
}

export class FindAreaCategoryDto extends PagingDto {
  cinemaProviderCinemaId?: string
}