import { Injectable } from '@angular/core'


import { HttpService } from '../http.service'
import { ExportOrderResultDto, FindOrderDateFilterDto, FindOrderDto, FindOrderEventDateFilterDto, FindOrderEventDto, OrderDto, OrderEventDetailDto, OrderEventDto, PageResultDto } from '../../dto'

@Injectable()
export class OrderService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/admin/v1/orders', findOrderDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<OrderDto>(`/admin/v1/orders/${id}`)
  }

  async exportExcel(dateFilterDto: FindOrderDateFilterDto) {
    return await this.httpService.get<ExportOrderResultDto>(`/admin/v1/orders/export`, dateFilterDto)
  }

  //////////////////////////////////////////////////////////////////////////
  // Order Event
  //////////////////////////////////////////////////////////////////////////

  async findOrderEvent(id: string, findOrderEventDto: FindOrderEventDto) {
    return await this.httpService.get<PageResultDto<OrderEventDto>>(`/admin/v1/orders/event/${id}`, findOrderEventDto)
  }

  async findOrderEventDetail(id: string) {
    return await this.httpService.get<OrderEventDetailDto>(`/admin/v1/orders/event/order/${id}`)
  }

  async exportOrderEventExcel(id: string, dateFilterDto: FindOrderEventDateFilterDto) {
    return await this.httpService.get<ExportOrderResultDto>(`/admin/v1/orders/event/${id}/export`, dateFilterDto)
  }

  async updateAllTicketsStatus(id: string) {
    return await this.httpService.put<boolean>(`/admin/v1/orders/event/${id}/ticket/all`)
  }

  async updateTicketStatus(id: string) {
    return await this.httpService.put<boolean>(`/admin/v1/orders/event/ticket/${id}`)
  }
}
