import * as _ from 'lodash'

import { FileDto } from './file.dto'
import { TranslationDto } from './translation.dto'
import { BaseDto } from './base.dto'

export class PageDto extends BaseDto {
  title: TranslationDto
}

export class PageDetailDto extends BaseDto {
  title: TranslationDto
  content: TranslationDto
  slug: string

  static create(pageDetail?: PageDetailDto) {
    return Object.assign(new PageDetailDto(), pageDetail)
  }
}

export class UpdatePageDto {
  title?: TranslationDto
  content?: TranslationDto
}
