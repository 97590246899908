export class TranslationDto<T = any> {
  en: T
  km: T
  cn: T

  public static create(en?: any, km?: any, cn?: any) {
    const translation = new TranslationDto()

    translation.en = en || ''
    translation.km = km || ''
    translation.cn = cn || ''

    return translation
  }

  public static createNullObject(en?: any, km?: any, cn?: any) {
    const translation = new TranslationDto()

    translation.en = en || null
    translation.km = km || null
    translation.cn = cn || null

    return translation
  }
}

export enum Branch {
  IH = 'IH',
  ONLINE = 'Online'
}