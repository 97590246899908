import _ from 'lodash'

import { BaseDto } from "./base.dto"
import { PagingDto } from "./paging.dto"

export class VoucherGroupDto extends BaseDto {
  codeGenerateLength: number
  voucherGenerateAmounts: number
  voucherType: string
  prefix: string
  amount: number
  discountType: number
  titleEn: string
  titleKm: string
  titleCn: string
  validFrom: string
  validTo: string
  minAmount: number
  maxDiscount: number
  vouchers: VoucherDto[]

  static create(data?: VoucherGroupDto) {
    return Object.assign(new VoucherGroupDto(), data)
  }
}

export class VoucherDto extends BaseDto {
  code: number
  voucherType: number
  amount: number
  discount: number
  discountType: number
  validFrom: string
  validTo: string
  minAmount: number
  usedCount: number
  limitUsage: number
  maxDiscount: number
  isValid: boolean

  static create(data?: VoucherDto) {
    return Object.assign(new VoucherDto(), data)
  }
}

export class CreateVoucherDto {
  codeGenerateLength: number
  voucherGenerateAmounts: number
  voucherType: string
  prefix?: string
  amount: number
  discount: number
  discountType: number
  titleEn: string
  titleKm: string
  titleCn: string
  validFrom: string
  validTo: string
  minAmount: number
  maxDiscount: number
}

export class FindVoucherDto extends PagingDto {
  searchText?: string
  voucherType: string
}